var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","align":"stretch"}},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$lang("applicationManager.batch.title")))])]),_c('v-data-table',{staticClass:"elevation-0",class:{ 'row-pointer': _vm.hasEditAccess },attrs:{"headers":_vm.headers,"items":_vm.batches,"search":_vm.search,"loading":_vm.isLoading},on:{"click:row":_vm.handleClickByTableRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$lang('components.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),(_vm.hasCreateAccess)?_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","small":"","fab":"","to":{
                  name: 'batch_new',
                }}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)]},proxy:true},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column py-1"},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"text-body-2 grey--text"},[_vm._v(" "+_vm._s(item.description)+" ")])])]}},{key:"item.scenarios",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(" count of "+_vm._s(item.scenarios.length)+" ")])]}},{key:"item.isDefault",fn:function(ref){
                var item = ref.item;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"disabled":!_vm.hasEditAccess,"dense":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleChangeDefaultBatch(item.id)}},model:{value:(item.isDefault),callback:function ($$v) {_vm.$set(item, "isDefault", $$v)},expression:"item.isDefault"}})]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleItemDeleting(item.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }