



























































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import BatchResponseModel from "@/application-manager/models/BatchResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component
export default class BatchesView extends mixins(AppSectionAccessMixin) {
  search = "";

  get headers(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang("applicationManager.name"),
        align: "start",
        value: "name",
        sortable: false,
        width: "50%",
      },
      {
        text: this.$lang("applicationManager.scenario.title"),
        align: "start",
        value: "scenarios",
        sortable: false,
        width: "30%",
      },
      {
        text: this.$lang("applicationManager.default"),
        align: "start",
        value: "isDefault",
        sortable: false,
        width: "10%",
      },
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang("commonConfig.actions"),
              value: "actions",
              align: "end",
              sortable: false,
              width: "10%",
            },
          ]
        : []),
    ];
  }

  get isLoading(): boolean {
    return this.$store.state.batchStore.isLoading;
  }

  get batches(): Array<BatchResponseModel> {
    return this.$store.state.batchStore.batches;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.batch.title")
    );
  }

  async mounted() {
    await this.$store.dispatch("loadBatches");
  }

  async handleItemDeleting(batchId: number) {
    await this.$store.dispatch("deleteBatch", batchId);
  }

  async handleChangeDefaultBatch(batchId: number) {
    await this.$store.dispatch("changeDefaultBatch", batchId);
  }

  handleClickByTableRow(item: BatchResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: "batch_view",
      params: {
        batchId: String(item.id),
      },
    });
  }
}
